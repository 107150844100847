import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { GroupWallPageRoutingModule } from './group-wall-routing.module';

import { GroupWallPage } from './group-wall.page';
import { ComponentsModule } from 'src/app/components/components.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,ReactiveFormsModule,
    IonicModule,
    GroupWallPageRoutingModule,
    ComponentsModule
  ],
  declarations: [GroupWallPage]
})
export class GroupWallPageModule {}
