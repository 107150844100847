import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PostService } from 'src/app/services/post/post.service';
import { environment } from 'src/environments/environment';
import { NavParams } from '@ionic/angular';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/services/logging/logging.service';
import { UserAnalyticsService } from 'src/app/services/user-analytics/user-analytics.service';
import { casePostCreationObject } from 'src/app/services/post/post.interface';
@Component({
    selector: 'app-share-post',
    templateUrl: './share-post.page.html',
    styleUrls: ['./share-post.page.scss'],
})
export class SharePostPage implements OnInit {
    pageTitle: any;
    pageMessage: any;
    buttonText: any;
    dismissButtonText: any;
    modalData: any;
    noOfRowsForScreen: any;
    platformType: any;
    desktop: boolean;
    comments: any;
    groupInfo: any;
    originalPostId: any;
    allowOthersToShare: boolean = false;
    public AppName: any = environment.Appname;
    constructor(public modalCtrl: ModalController, public postService: PostService, public navParams: NavParams, public router: Router, public loggingService: LoggingService, public userAnalyticsService: UserAnalyticsService) {
        const modalElement = document.querySelector('ion-modal');
        this.modalData = modalElement.componentProps;
        this.comments =
            console.log("🚀 ~ file: share-post.page.ts:32 ~ SharePostPage ~ constructor ~ this.modalData:", this.modalData)
        this.originalPostId = this.modalData?.originalPostId;
        this.groupInfo = this.modalData?.groupInfo;
        this.platformType = sessionStorage.getItem('platform');
        if (this.platformType == "web") {
            this.desktop = true;
            this.noOfRowsForScreen = 12;
        } else {
            this.desktop = false;
            this.noOfRowsForScreen = 4;
        }
        this.loggingService.fetchGlobalConstants();
    }

    ngOnInit() {
        this.userAnalyticsService.trackPagesHandler('SharePostPage');
    }


    dismiss() {
        // console.log("modal data", this.modalData);

        if (this.modalData.comingFrom == "caseCreationPage") {
            let homePageDismiss = { value: "No I am done" };
            this.modalCtrl.dismiss(homePageDismiss);
        } else if (this.modalData.comingFrom == "shareGroupsPage") {
            this.modalCtrl.dismiss();
        }
    }

    showGroupsFunction() {
        if (this.buttonText == "Show groups") {
            let homePageDismiss = {
                shareComment: this.comments,
                value: "Show Groups"
            };
            this.modalCtrl.dismiss(homePageDismiss);
        } else if (this.buttonText == "Share") {
            if (this.groupInfo.groupType == 'Open' || this.groupInfo.groupType == 'Public') {
                this.allowOthersToShare = true;
            }
            else {
                this.allowOthersToShare = false;
            }

            let details: casePostCreationObject = {
                thoughts: '',
                caseTitle: '',
                extract: '',
                questions: '',
                gid: this.groupInfo._id,
                creationDate: new Date(),
                updatedDate: new Date(),
                disabledBy: '',
                disabledDate: '',
                Disable: false,
                identify: '',
                photos: [],
                type: "Shared",
                comment: this.comments,
                postRef: this.originalPostId,
                postVisibility: 'Groups',
                specificConnectionsVisibility: [],
                shares: [],
                commentId: [],
                updates: [],
                userExpression: [],
                openGroupFollow: [],
                allowOthersToShare: this.allowOthersToShare,
                userFollow: [],
                pushNotificationUsers: [],
                postOrCaseSecurityPolicy: false,
                offlinePublishStatus: "not started"
            }
            // let details = {
            // 	thoughts: "",
            // 	caseTitle: "",
            // 	extract: "",
            // 	questions: "",
            // 	gid: this.groupInfo._id,
            // 	creationDate: new Date(),
            // 	updatedDate: new Date(),
            // 	disabledBy: "",
            // 	disabledDate: "",
            // 	Disable: false,
            // 	identify: "",
            // 	photos: [],
            // 	type: "Shared",
            // 	comment: this.comments,
            // 	postRef: this.originalPostId,
            // 	postVisibility: "",
            // 	specificConnectionsVisibility: [],
            // 	shares: [] /** THIS PARAMETER IS FOR KEEPING THE IDS AND COUNT OF TOTAL SHARES OF ORIGINAL POST */,
            // 	commentId: [],
            // 	updates: [],
            // 	userExpression: [],
            // 	openGroupFollow: [],
            // 	allowOthersToShare: this.allowOthersToShare,
            // 	userFollow: [] /** USERIDS OF PEOPLE COMMENTED AND CREATED THE POST ARE ADDED TO THIS ARRAY */,
            // 	pushNotificationUsers: [],
            // 	postOrCaseSecurityPolicy: false
            // };

            this.postService.addPost(details).subscribe(
                res => {
                    if (res.error == false) {
                        this.loggingService.logMessage(this.loggingService.loggingConstraints.log_info, 'sharePostPage', 'showGroupsFunction()', '');
                        let updatedPostComment = this.comments;
                        this.groupInfo.shared = true;
                        this.postService.updateSpecificPostObjectInTheDisplay(res.resObject);
                        this.modalCtrl.dismiss(updatedPostComment);
                    }
                    else {
                        this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'sharePostPage', 'showGroupsFunction()', '', 'Error in response');
                    }
                },
                (error => {
                    this.loggingService.httpErrorHandler(this.loggingService.loggingConstraints.log_error, 'sharePostPage', 'showGroupsFunction()', '', error);
                })
            );
        }
    }
}
